// from Webamp demo

export const initialTracks = [
  {
    url: '/uprising.mp3',
    duration: 432,
    metaData: {
      title: 'Uprising',
      artist: 'Muse',
      album: 'The Resistance',
    },
  },
  {
    url: '/supremacy.mp3',
    duration: 301,
    metaData: {
      title: 'Supremacy',
      artist: 'Muse',
      album: 'The 2nd Law',
    },
  },
];
